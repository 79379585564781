import React from 'react';
import {Section, Banner, Article01, Article02, Article03, Article04, BtnGroup, SlideItem, Article05, MaxWidth} from "./style";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import ExLottie from './Lottie';

const Intro = () => {
    return (
        <Section>
            <Banner>
                <h2>
                    Mutual Growth<br/>
                    with <span>KIWON-TECH</span>NOLOGY
                </h2>
            </Banner>
            <MaxWidth>
                <Article01>
                    <div>
                        <div className="left">
                            <h2>주식회사 기원테크</h2>
                            <span>설립일<span>1994. 06. 09</span></span>
                        </div>
                        <div className="right">
                            <p>
                                <span> 대한민국, 더 나아가 세계 IT 시장의 Global Standard를 제시하는 IT업계 Leader로 성장한 기원테크는</span>

                                <span>  다양한 경험과 기원테크만의 기술(KIWON-TECHNOLOGY)을 토대로<br/>
                                모두가 보다 안전하고, 편리한 비즈니스 성장의 발판을 갖출 수 있도록 합니다.</span>

                                작은 단위의 업무부터 비즈니스 성장의 순간까지 모두를 아우를 수 있는 혁신적인 IT Solution을 선보이겠습니다.
                            </p>
                        </div>
                    </div>
                </Article01>
            </MaxWidth>
                <Article02>
                    <h2>우리가 성장할 수 있었던 <span>원동력, 3C</span></h2>
                    <ul>
                        <li>
                            <div className="left">
                                <h3><span>C</span>ustomer-centric</h3>
                            </div>
                            <div className="right">
                                <h3>
                                    <span>고객의 비전을 실현</span>시킵니다.
                                </h3>
                                <p>
                                    <span>고객의 비전이 반영된 혁신 기술은 고객에게</span>
                                    특별한 경험을 선사하고, 기원테크의 노하우로 쌓입니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="left">
                                <h3><span>C</span>hallenging</h3>
                            </div>
                            <div className="right">
                                <h3>
                                    <span>새로운 도전</span>을 이어갑니다.
                                </h3>
                                <p>
                                    <span>기꺼이 혁신과 변화에 도전하여</span>
                                    훌륭한 경험을 쌓고, 독창적인 New Paradigm을 만들어갑니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="left">
                                <h3><span>C</span>ollaborative</h3>
                            </div>
                            <div className="right">
                                <h3>
                                    <span>함께 성장</span>해 나아갑니다.
                                </h3>
                                <p>
                                    <span>기원테크와 기원人은 함께 IT 전문가로 성장합니다.</span>
                                    더 나아가 안전하고 편리한 IT Solution을 통해 사회 전역의 발전에 기여합니다.
                                </p>
                            </div>
                        </li>
                    </ul>
{/*                    <div className="lottie">
                        <ExLottie/>
                    </div>*/}
                </Article02>

            <Article03>
                <div>
                    <h3>
                        <span>3C를 추구하는 사람들이 모여,</span>
                        <span>처음부터 끝까지</span>
                        <span>’직접’ 혁신을 만들어갑니다.</span>
                        <span>We Are</span>
                    </h3>
                    <img src="/img/img-intro__logo01.png" alt="" className="pc"/>
                    <img src="/img/img-intro__logo01-sub.png" alt="" className="pc"/>
                    <img src="/img/img-intro__logo01_m.png" alt="" className="mb"/>
                    <img src="/img/img-intro__logo01-sub_m.png" alt="" className="mb"/>
                    <img src="/img/img-intro__logo01_mm.png" alt="" className="mm"/>
                    <img src="/img/img-intro__logo01_mmm.png" alt="" className="mmm"/>
                </div>
            </Article03>
            <Article04>
                <div className="Article04Top">
                    <h3>
                        <span>KIWON-TECHNOLOGY는</span>
                        이렇게 고도화되었습니다.
                    </h3>
                    <BtnGroup>
                        <button type="button" className="swiper-button-prev">
                        </button>
                        <button type="button" className="swiper-button-next">
                        </button>
                    </BtnGroup>
                </div>
                <Swiper
                    // install Swiper modules
                    breakpoints={{
                        1700: {
                            slidesPerView: 3.5
                        },
                        1300: {
                            slidesPerView: 3.5
                        },
                        1100: {
                            slidesPerView: 2.5
                        },
                        450: {
                            slidesPerView: 1

                        },
                        300: {
                            slidesPerView: 1
                        }
                    }}
                    modules={[Scrollbar, Navigation, A11y]}
                    spaceBetween={50}
                    scrollbar={{draggable: true}}
                    navigation={{
                        nextEl: '.swiper-button-next', // 다음 버튼 클래스명
                        prevEl: '.swiper-button-prev', // 이전 버튼 클래스명
                    }}

                >
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2022</h3>
                            <ul>
                                <li>표적형 이메일 공격 차단을 위한 TTA 표준으로 제정</li>
                                <li>SendGUARD 발신메일 첨부파일 관리 기능 업데이트</li>
                                <li>베트남, Vnetwork와 사이버 보안 전시회 참여</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2021</h3>
                            <ul>
                                <li>ReceiveGUARD GS인증 1등급 획득</li>
                                <li>Gartner 인증 이메일 보안 벤더로 국내 유일 선정</li>
                                <li>TTA 주관 V&V 시험, EG-Platform 모든 항목 합격</li>
                                <li>SECU E Cloud 그룹웨어 오픈</li>
                                <li>KOTRA와 이메일 무역 사기 관련 협업 프로젝트 진행</li>
                                <li>KISIA와 랜섬웨어 대응 지원 프로젝트 진행</li>
                                <li>2021 월드IT쇼 참가</li>
                                <li>「서울시 우수기업(Hi Seoul 기업)」 선정</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2020</h3>
                            <ul>
                                <li>발신메일 보안 솔루션 SendGUARD 출시</li>
                                <li>보안메일 서비스 SECU E Cloud 출시</li>
                                <li>웹 기반 업무 관리 프로그램 출시</li>
                                <li>NETNAM과 ‘베트남 표준 머신러닝 이메일 보안 토탈</li>
                                <li>솔루션 상용화 계약‘ 체결</li>
                                <li>「글로벌IP(지적재산권) 스타기업」 선정</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2019</h3>
                            <ul>
                                <li>Gartner 이메일 보안 리포트에서 지역 대표 기업으로 선정</li>
                                <li>일본, 베트남, 싱가포르에 RG-Cloud 출시</li>
                                <li>대한물리치료사협회 웹 통합 시스템 컨설팅 및 개발</li>
                                <li>대한의사협회 폴리시 신규 사이트 구축</li>
                                <li>대한행정관리자협회 통합 웹 시스템 컨설팅 및 개발</li>
                                <li>포항 치매 환자 실종방지 시스템 컨설팅 및 개발</li>

                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2018</h3>
                            <ul>
                                <li>ReceiveGUARD CC인증(EAL2) 획득</li>
                                <li>대한적십자사 차세대 보건 안전강습관리 시스템 구축</li>
                                <li>대한물리치료사협회 교육시스템 고도화 및 신규 개발</li>
                                <li>포항테크노파크 스마트 주차 실시간 안내서비스 통합 플랫폼 개발 및 구축</li>
                                <li>울산테크노파크재단 전자메일시스템 고도화 사업</li>
                                <li>일본 법인 설립 (Secu. Japan Co., Ltd.)</li>
                                <li>싱가포르 법인 설립 (Connectivity Global Pte.Ltd.)</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2017</h3>
                            <ul>
                                <li>지능형 및 학습형 메일 방화벽 장치 특허 등록</li>
                                <li>싱가포르, ReceiveGUARD 클라우드 서비스 출시</li>
                                <li>일본, 베트남에 SecuMail 출시</li>
                                <li>베트남 국방부 SecuMail 도입</li>
                                <li>2017년 KOTRA 정상외교 미국 정보보안 사절단에 참가</li>
                                <li>베트남 Vnetwork와 파트너 계약</li>
                                <li>일본 Taiyo/I-Service MOU 체결</li>
                                <li>오만 IT 기업(OZONE UNITED 외 1)과 MOU 체결</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2016</h3>
                            <ul>
                                <li>대한의사협회 회원관리 시스템 및 홈페이지 통합 개편</li>
                                <li>서울대학교 TEPS 시스템 개편</li>
                                <li>성균관대학교 MOU 체결</li>
                                <li>2016년 K-솔루션 페어 및 미국 ICT 정보보안 민관합동 사절단에 참가</li>
                                <li>‘2016년 대한민국 기업대상 시상식’에서 2년 연속 보안솔루션 대상 수상</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2015</h3>
                            <ul>
                                <li>평생교육원 네트워크 및 LMS 시스템 개발</li>
                                <li>세계 최초 학습형 메일 방화벽 SCM GUARD 출시</li>
                                <li>우수 소프트웨어 선정 및 전시회 참가</li>
                                <li>‘2015년 대한민국 기업대상 시상식’에서 보안솔루션 대상 수상</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2014</h3>
                            <ul>
                                <li>창립 20주년 ㈜기원테크 법인 전환</li>
                                <li>SecuMail 특허 출원</li>
                                <li>보안메일서비스, SecuMail 출시</li>
                                <li>시큐암호화 서비스 시작</li>
                                <li>PLC 장비 제어 및 연동 프로그램 개발</li>
                                <li>IDC Care System 도입</li>
                                <li>한국복지사협회 통합 전산 컨설팅</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2013</h3>
                            <ul>
                                <li>중국 대련, 청도, 향주 등 염색/봉제/편직 공장 전산화</li>
                                <li>고객관리/유지보수관리/실시간재고관리/부동산 프로그램 개발</li>
                                <li>LG통신망 연결 GPS 시스템 개발</li>
                                <li>DOOSAN Infracore Website 구축</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2012 ~ 2011</h3>
                            <ul>
                                <li>실시간 통신 ARIMI 베타 버전 서비스 개시</li>
                                <li>IPX 접속 프로그램 개발</li>
                                <li>근로 복지 공단 메일 시스템 개발</li>
                                <li>멀티 다운로드 프로그램 개발</li>
                                <li>물류 배송 자동관리 프로그램 개발</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2010~2007</h3>
                            <ul>
                                <li>독립 IDC 센터 운영 개시 및 확장 (제2/3/4센터)</li>
                                <li>웹 메일 서비스 사업 확장</li>
                                <li>대용량 파일 서비스 개시</li>
                                <li>기원테크 통합 솔루션 서비스 개시</li>
                                <li>무선통신 실시간 영상 송수신 프로그램 개발</li>
                                <li>인쿠르트 알바 82 구인구직 사이트 개발</li>
                                <li>카모스(위치추적) 시스템 지능형 추적 개발</li>
                                <li>회원 CRM DB 통합 관리 프로그램 개발</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2006~2004
                            </h3>
                            <ul>
                                <li>삼성카드 제휴 계약 체결</li>
                                <li>창녕군청 새올 행정 시스템 개발</li>
                                <li>인트론 바이오테크놀로지 선계 관리 프로그램 개발</li>
                                <li>그룹웨어 서비스 개시 및 웹하드 프로그램 개발</li>
                                <li>실시간 자동 백업 프로그램 서비스 시작</li>
                                <li>메일 서비스 독립엔진 베타 버전 서비스 시작</li>
                                <li>대용량 전송 프로그램 개발</li>
                                <li>실버라이트 이용 대용량 전송 프로그램 서비스 개발</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>2003~1998
                            </h3>
                            <ul>
                                <li>연세의료원 및 세브란스병원 Website 구축/Wi 통합</li>
                                <li>삼성전자 Website 구축/Wi 통합</li>
                                <li>시흥시청 Website 구축</li>
                                <li>금융감독원 Website 구축</li>
                                <li>전자부품 및 Cable Ass’y 조립 업체 전산화</li>
                                <li>Barcode에 의한 입고/출고 시스템 개발</li>
                                <li>쇼핑몰 프로그램 출시</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>1997~1995</h3>
                            <ul>
                                <li>원단 편직/전사/염색 공장 전산화</li>
                                <li>봉제, 가방, 신발 생산관리 프로그램 출시</li>
                                <li>섬유 프로그램 출시</li>
                                <li>무역 관리 프로그램 출시</li>
                                <li>전원 플러그 생산 관리 프로그램 개발</li>
                                <li>미수금 외 관리 프로그램 개발</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideItem>
                            <h3>1994</h3>
                            <ul>
                                <li>기원테크 설립</li>
                                <li>유통 프로그램 출시</li>
                            </ul>
                        </SlideItem>
                    </SwiperSlide>
                </Swiper>
            </Article04>
            <Article05>
                <div className="article05Wrap">
                    <h3>
                        <span>기원테크는</span>
                        <span>세계 IT 시장의 <span>Global Standard를 제시하는</span></span>
                        <span>IT업계 Leader입니다.</span>
                    </h3>
                    <div className="mapWrap">
                        <img src="/img/img-introMap.png" alt=""/>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <ul>
                        <li>
                            <h3>Head Office (Seoul, Korea)</h3>
                            <p>
                                <span className="dis-b__pc">서울특별시 구로구 디지털로31길 53 이앤씨벤처드림타워 5차 509호</span>
                                <span>509 ENC Venture Dream Tower 5, 53, Digital-ro 31-gil,</span> Guro-gu, Seoul, Republic of Korea
                                <a href="http://kko.to/9PlPtfCtp" target="_blank">
                                    <img src="/img/icon/ico-intro__marker.png" alt=""/>
                                </a>
                            </p>
                        </li>
                        <li>
                            <h3>Branch Office (Busan, Korea)</h3>
                            <p>
                                <span className="dis-b__pc">부산광역시 부산진구 범일로 176 로얄팰리스2차 702호</span>
                                <span>702 Royal Palace 2, 176 Beomil-ro,</span>  Busanjin-gu, Busan, Republic of Korea
                                <a href="http://kko.to/rtECrNwE2"  target="_blank">
                                    <img src="/img/icon/ico-intro__marker.png" alt=""/>
                                </a>
                            </p>
                        </li>
                        <li>
                            <h3>Branch Office (Japan)</h3>
                            <p>
                                〒550-0011 Japan Osaka-city Nishi-Ku Awaza 1-14-15 Sanwa BLDG
                            </p>
                        </li>
                        <li>
                            <h3>Head Branch Office (Vietnam)</h3>
                            <p>
                                Unit 23.06, 23rd Fl, UOA Tower, 06 Tan Trao St, Tan Phu Ward, Dist 7
                            </p>
                        </li>
                        <li>
                            <h3>Virtual IDC (Singapore)</h3>
                            <p>
                                <span className="dis-b__pc">Blk 1015 Geylang East Avenue 3, #04-117 Geylang East Industrial</span>
                                Estate Singapore 389730
                            </p>
                        </li>
                    </ul>
                </div>
            </Article05>
        </Section>
    );
};

export default Intro;