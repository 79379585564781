import styled, {keyframes} from "styled-components"

const spin = keyframes`
  0% { transform: rotate(0deg); }
  8.3% { transform: rotate(60deg); }
  16.6% { transform: rotate(120deg); }
  25% { transform: rotate(180deg); }
  33.3% { transform: rotate(240deg); }
  41.6% { transform: rotate(300deg); }
  50% { transform: rotate(360deg); }
  58.3% { transform: rotate(420deg); }
  66.6% { transform: rotate(480deg); }
  75% { transform: rotate(540deg); }
  83.3% { transform: rotate(600deg); }
  91.6% { transform: rotate(660deg); }
  100% { transform: rotate(720deg); }
`

const mapAni = keyframes`
  0% {
    transform: scale( 1.5 )
  }
  50% {
    transform: scale( 2 )
  }
  100% {
    transform: scale( 1.5 )
  }
`
const articleAni1 = keyframes`
  0%{
    opacity: 0;
    transform: translateY(100px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
`
const articleAni2 = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`
export const MaxWidth =styled.div`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`
export const Section = styled.section`

`
export const Banner = styled.div`
  width: 100%;
  background-image: url("/img/img-banner01.png");
  background-repeat:no-repeat;
  background-size: cover;
  padding: 0 100px;
  box-sizing: border-box;
  h2{
    color:#fff;
    font-size: 60px;
    font-weight: 700;
    padding: 340px 0 90px;
    line-height: 1.4;
    animation: ${articleAni2} 2s;
    @media screen and (max-width: 840px) {
      padding: 255px 0 45px;
      font-size: 44px;
    }
    @media screen and (max-width: 640px) {
      padding: 270px 0 30px;
      font-size: 32px;
    }
    span{
      color: #bcd330;
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 840px) {
    background-position: -300px;
  }
  @media screen and (max-width: 640px) {
    padding: 0 30px;
  }
`
export const Article01 = styled.article`
  margin: 100px 0 200px;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 990px) {
    margin: 50px 0 80px;
  }
  & > div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 1170px) {
     display: block;
    }
    .left{
      @media screen and (max-width: 1170px) {
        margin-bottom: 40px;
      }
      @media screen and (max-width: 450px) {
        margin-bottom: 30px;
      }
      h2{
        color: #222;
        font-size: 42px;
        font-weight: 700;
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 820px) {
          font-size: 34px;
        }
        @media screen and (max-width: 450px) {
          font-size: 30px;
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
        &:after{
          content: "";
          display: inline-block;
          width: 20px;
          height: 2px;
          background-color: #222;
          position: absolute;
          left: 3px;
          bottom: 0;
        }
      }
      span{
        color: #222;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.3;
        @media screen and (max-width: 820px) {
          font-size: 18px;
        }
        @media screen and (max-width: 450px) {
          font-size: 16px;
        }
        span{
          display: block;
          @media screen and (max-width: 1170px) {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }
    .right{
      color: #636363;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.4;
      @media screen and (max-width: 820px) {
        font-size: 16px;
      }
      
      span{
        display: block;
        margin-bottom: 30px;
        word-break: keep-all;
        @media screen and (max-width: 820px) {
          margin-bottom: 20px;
        }
      }
    }
  }
`
export const Article02 = styled.article`
  margin: 110px auto 140px;
  animation: ${articleAni1} 2s;
  max-width: 1400px;
  @media screen and (max-width: 990px) {
   margin: 0 auto 50px;
  }
  h2{
    font-size: 46px;
    font-weight: 600;
    color: #222;
    margin-bottom: 70px;
    @media screen and (max-width: 1400px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 990px) {
      font-size: 38px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 530px) {
      font-size: 30px;
      margin-bottom: 30px;
      line-height: 1.4;
      padding: 0 30px;
    }
    span{
      @media screen and (max-width: 530px) {
        display: block;
      }
    }
  }
  &.lottie {
      animation: ${articleAni1} 2s;
  }  
  ul{
    li{
      display: flex;
      align-items: start;
      padding: 30px 0 50px;
      border-bottom: 1px solid #d5d5d5;
      &:last-of-type{
        border-bottom: 0;
      }
      @media screen and (max-width: 1400px) {
        padding: 30px 40px 50px;
      }
      @media screen and (max-width: 990px) {
        display: block;
        padding: 30px 0px 50px;
        margin: 0 40px;
      }
      @media screen and (max-width: 530px) {
        display: block;
        padding: 30px 0px 40px;
        margin: 0 30px;
      }
      &:last-of-type{
        @media screen and (max-width: 990px) {
         border-bottom: 0;
        }
      }
      & > div{
        width: 50%;
        @media screen and (max-width: 990px) {
          width: 100%;
        }
      }
      .left{
        h3{
          color:#333;
          font-weight: 600;
          font-size: 46px;
          background-image: url("/img/ico-intro.png");
          background-repeat: no-repeat;
          background-position: left center;
          padding-left: 50px;
          @media screen and (max-width: 990px) {
            font-size: 38px;
          }
          @media screen and (max-width: 530px) {
            font-size: 28px;
            background-size: 20px;
            padding-left: 35px;
          }
          span{
            color: #bcd330;
            font-size: 58px;
            @media screen and (max-width: 990px) {
              font-size: 48px;
            }
            @media screen and (max-width: 530px) {
              font-size: 36px;
            }
          }
        }
      }
      .right{
        margin-top: 20px;
        h3{
          color: #333;
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 20px;
          @media screen and (max-width: 990px) {
            font-size: 26px;
            margin-left: 50px;
            margin-bottom: 15px;
          }
          @media screen and (max-width: 530px) {
            font-size: 22px;
            padding-left: 0px;
            margin-left: 35px;
            margin-bottom: 10px;
          }
          span{
            color: #b3c938;
          }
        }
        p{
          color: #3f3f3f;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.4;
          @media screen and (max-width: 990px) {
            font-size: 16px;
            margin-left: 50px;
          }
          @media screen and (max-width: 530px) {
            font-size: 16px;
            margin-left: 35px;
          }
          span{
            display: block;
          }
        }
      }
    }
  }
`
export const Article03 = styled.div`
  text-align: center;
  width: 100%;
  height: 1120px;
  position: relative;
  background-image: url("/img/img-intro__banner02.png");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media screen and (max-width: 1170px) {
    height: 950px;
  }
  @media screen and (max-width: 730px) {
    height: 850px;
  }
  @media screen and (max-width: 565px) {
    height: 750px;
  }
  @media screen and (max-width: 440px) {
    height: 750px;
  }
  & > div{
    max-width: 1480px;
    height: calc(100% - 240px);
    width: 100%;
    position: relative;
    padding: 0px 40px;
    box-sizing: border-box;
    @media screen and (max-width: 1400px) {
      height: calc(100% - 170px);
    }
    @media screen and (max-width: 1170px) {
      height: 800px;
    }
    @media screen and (max-width: 730px) {
      height: 700px;
    }
    @media screen and (max-width: 565px) {
      height: 650px;
      padding: 0 30px;
    }
    @media screen and (max-width: 440px) {
      height: 600px;
    }
    h3{
      text-align: left;
      span{
        display: block;
        font-size: 36px;
        font-weight: 600;
        color: #636466;
        margin-bottom: 15px;
        @media screen and (max-width: 850px) {
          font-size: 28px;
        }
        @media screen and (max-width: 565px) {
          font-size: 30px;
        }
        @media screen and (max-width: 440px) {
          font-size: 28px;
        }
        @media screen and (max-width: 380px) {
          font-size: 25px;
        }
        &:nth-of-type(3){
          color: #fff;
        }
        &:nth-of-type(4){
          color: rgba(255, 255, 255, 0.05);
          font-weight: 600;
          font-size: 60px;
          margin-top: 60px;
          @media screen and (max-width: 850px) {
            font-size: 42px;
            margin-top: 40px;
          }
          @media screen and (max-width: 565px) {
            font-size: 36px;
            margin-top: 25px;
          }
        }
      }
    }
  }
  img{
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 100%;
    &:nth-of-type(2){
      animation: ${spin} 20s infinite;
    }
    &.mb{
      display: none;
      @media screen and (max-width: 850px) {
        display: inline;
        height: auto;
        right: 20px;
      }
      @media screen and (max-width: 730px) {
        height: 450px;
      }
      @media screen and (max-width: 565px) {
        display: none;
      }
    }
    &.mm{
      display: none;
      height: auto;
      @media screen and (max-width: 565px) {
        display: inline;
        right: 15px;
      }
      @media screen and (max-width: 440px) {
        display: none;
      }
    }
    &.mmm{
      display: none;
      height: 310px;
      right: 15px;
      bottom: 30px;
      @media screen and (max-width: 440px) {
        display: inline;
      }
    }
    @media screen and (max-width: 1400px) {
      right: 40px;
    }
    @media screen and (max-width: 1170px) {
      height: 600px;
    }
    &.pc{
      @media screen and (max-width: 850px) {
        display: none;
      }
    }
  }
`
export const Article04 = styled.div`
  width: calc(100% - 230px);
  margin: 100px 0 140px 230px;
  @media screen and (max-width: 1300px) {
    margin: 120px 0px 120px 40px;
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 820px) {
    margin: 100px 0px 100px 40px;
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 530px) {
    margin: 80px 0px 70px 30px;
    width: calc(100% - 30px);
  }
  .Article04Top{
    position: relative;
    margin-bottom: 50px;
    @media screen and (max-width: 550px) {
      margin-bottom: 40px;
    }
 
  h3{
    font-size: 42px;
    color: #222;
    font-weight: 600;
    line-height: 1.3;
    @media screen and (max-width: 820px) {
      font-size: 34px;
    }
    @media screen and (max-width: 550px) {
      font-size: 30px;
      padding-bottom: 50px;
    }
    span{
      display: block;
    }
  }
  }
  .swiper{
    position: relative;
    &:before{
      content: "";
      display: inline-block;
      width: calc(100% - 40px);
      height: 1px;
      background-color: #bcd330;
      position: absolute;
      top: 12px;
      left: 40px;
    }
  }
  .swiper-scrollbar-drag{
    background-color: #a8a8a8;
    border-radius: 0;
  }
  .swiper-scrollbar{
    background-color:#efefef;
    @media screen and (max-width: 820px) {
      width: calc(100% - 60px);
    }
  }
`
export const BtnGroup = styled.div`
  position: absolute;
  right: 100px;
  top: 0;
  @media screen and (max-width: 1300px) {
    right: 40px;  
    bottom: 10px;  
    top: auto;
  }
  @media screen and (max-width: 550px) {
    left: 0px;
    right: auto;
    bottom: 0px;
    top: auto;
  }
  button{
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }
  .swiper-button-prev{
    width: 38px;
    height: 24px;
    background-image: url("/img/ico-prev-arrow_dg.png");
    background-repeat: no-repeat;
    margin-right: 30px;
    &.swiper-button-disabled{
      background-image: url("/img/ico-prev-arrow_rg.png");
    }
  }
  .swiper-button-next{
    width: 38px;
    height: 24px;
    background-image: url("/img/ico-next-arrow_dg.png");
    background-repeat: no-repeat;
    &.swiper-button-disabled{
      background-image: url("/img/ico-next-arrow_rg.png");
    }
  }
`
export const SlideItem = styled.div`
  padding-top: 40px;
  position: relative;
  &:before{
    content: "";
    display: inline-block;
    background-image: url("/img/icon/ico-intro__before.png");
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    position: absolute;
    left: 23px;
    top: 0;
  }
  h3{
    color:#bcd330;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 25px;
    @media screen and (max-width: 1300px) {
      font-size: 34px;
    }
    @media screen and (max-width: 530px) {
      font-size: 30px;
    }
  }
  ul{
    padding-bottom: 120px;
    @media screen and (max-width: 530px) {
      padding-bottom: 70px;
    }
  }
  li{
    color: #575757;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    position: relative;
    padding-left: 15px;
    line-height: 1.3;
    word-break: keep-all;
    @media screen and (max-width: 1300px) {
      font-size: 18px;
    }
    @media screen and (max-width: 530px) {
      font-size: 16px;
    }
    &:before{
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      background-color: #bcd330;
      left: 0px;
      top: 6px;
    }
  }
`
export const Article05 = styled.div`
  background-color: #141c23;
  text-align: center;
  padding: 90px 0;
  @media screen and (max-width: 630px) {
    padding: 60px 0px;
  }
  .article05Wrap{
    max-width: 1480px;
    padding: 0 40px;
    box-sizing: border-box;
    margin: 0 auto;
    @media screen and (max-width: 1000px) {
     padding: 0;
      overflow: hidden;
    }
   
  }
  h3{
    margin-bottom: 40px;
   & > span{
      display: block;
      color: #748595;
      font-size: 46px;
      font-weight: 500;
      line-height: 1.4;
      @media screen and (max-width: 820px) {
        font-size: 34px;
      }
      @media screen and (max-width: 630px) {
        font-size: 30px;
      }
     @media screen and (max-width: 380px) {
       font-size: 24px;
     }
      &:nth-of-type(3){
        color: #fff;
      }
     span{
       @media screen and (max-width: 630px) {
        display: block;
       }
     }
    }
  }
  .mapWrap{
    position: relative;
    @media screen and (max-width: 1000px) {
      overflow: hidden;
      height: 650px;
      width: 1000px;
    }
    img{
      max-width: 100%;
      @media screen and (max-width: 1000px) {
        width: 1434px;
        max-width: unset;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      @media screen and (max-width: 600px) {
        right: -60px;
        left: auto;
        transform: translateX(0);
      }
    }
    div{
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #bcd330;
      &:before{
        content: "";
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(188, 211, 48, 0.3);
        border-radius: 50%;
        animation: ${mapAni} 2.5s infinite;
      }
      &:nth-of-type(1){
        top: 41%;
        left: 40%;
        @media screen and (max-width: 1000px) {
          top: 275px;
          left: 360px;
        }
        @media screen and (max-width: 600px) {
          top: 275px;
          left: 200px;
        }
      }
      &:nth-of-type(2){
        top: 42%;
        left: 43%;
        @media screen and (max-width: 1000px) {
          top: 280px;
          left: 398px;
        }
        @media screen and (max-width: 600px) {
          top: 280px;
          left: 238px;
        }
      }
      &:nth-of-type(3){
        top: 55%;
        left: 34.5%;
        @media screen and (max-width: 1000px) {
          top: 375px;
          left: 280px;
        }
        @media screen and (max-width: 600px) {
          top: 375px;
          left: 120px;
        }
      }
      &:nth-of-type(4){
        top: 65%;
        left: 34%;
        @media screen and (max-width: 1000px) {
          top: 435px;
          left: 270px;
        }
        @media screen and (max-width: 600px) {
          top: 435px;
          left: 110px;
        }
      }
    }
  }
  ul{
    li{
      display: flex;
      color: #ffffff;
      text-align: left;
      padding: 30px 0;
      border-bottom: 1px solid #8a8e91;
      @media screen and (max-width: 1000px) {
        margin: 0 40px;
      }
      @media screen and (max-width: 820px) {
        display: block;
        padding: 40px 0;
      }
      @media screen and (max-width: 530px) {
        margin: 0 30px;
      }
      &:last-of-type{
        border-bottom: 0;
      }
      h3{
        font-weight: 500;
        font-size: 28px;
        width: 35%;
        @media screen and (max-width: 910px) {
          font-size: 24px;
          margin-right: 10px;
        }
        @media screen and (max-width: 820px) {
          width: 100%;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 380px) {
          font-size: 20px;
        }
      }
      p{
        font-size: 20px;
        font-weight: 400;
        line-height: 1.6;
        position: relative;
        width: 65%;
        @media screen and (max-width: 910px) {
          font-size: 16px;
        }
        @media screen and (max-width: 820px) {
          width: 100%;
        }
        @media screen and (max-width: 380px) {
          font-size: 14px;
        }
        .dis-b__pc{
          display: block;
        }
        span{
          @media screen and (max-width: 1170px) {
            display: block;
          }
        }
        a{
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          @media screen and (max-width: 530px) {
           top: -45px;
            right: 0;
            transform: translateY(0);
          }
        }
      }
    }
  }
`